/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 29, 2020 */



@font-face {
    font-family: 'korinnabold';
    src: url('korinna_bold-webfont.woff2') format('woff2'),
         url('korinna_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}