@font-face {
  font-family: 'korinnabold';
  src: url('./fontFile/korinna_bold-webfont.woff2') format('woff2'),
       url('./fontFile/korinna_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}

.blurb_text {
  font-family: 'Alice', serif;
}

body .test {
	text-align:  left;
	color:       #000;
	font-family: 'Alice', serif;
	font-size:   120%;
	line-height: 1.6;
	margin:      0;
	padding:     2em 2em 5em;
	background:  rgb(253, 253, 253);
	margin:      auto;
	max-width:   33em;
	color:       rgb(10, 10, 10);
}

h3 {
  font-weight:    normal;
  font-family: korinnabold;
  text-transform: uppercase;
	font-size:      90%;
	letter-spacing: 0.10em;
  margin:         1.75em 0 0.75em;
  color:       #403523;
}

h4 .test {
	font-weight:    normal;
	font-size:      150%;
	letter-spacing: 0.1em;
	margin:         1.5em 0 0.75em;
}

h5 {
	font-weight: normal;
	font-style:  italic;
	font-size:   150%;
	margin:      1.5em 0 0.75em;
}

nav {
  background-color: #F36D48;
}





.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.not_mobile {
  display: none;
}

.mobile {
  display: block;
}

@media only screen and (min-width: 769px) {
  .not_mobile {
      display: block;
  }
  .mobile {
      display: none;
  }
  body .test {
    text-align:  left;
    color:       #000;
    font-family: georgia, serif;
    font-size:   130%;
    line-height: 1.6;
    margin:      0;
    padding:     2em 2em 5em;
    background:  rgb(253, 253, 253);
    margin:      auto;
    max-width:   33em;
    color:       rgb(10, 10, 10);
  }

  h3 {
    font-weight:    normal;
    font-family: korinnabold;
    text-transform: uppercase;
  
    font-size:      125%;
    letter-spacing: 0.10em;
    margin:         1.75em 0 0.75em;
    color:       #403523;
  }
}